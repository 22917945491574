import { Link } from "react-router-dom";

export default function ProjectThumbnail({ project }) {
    const projectData = project?.attributes;
    return (
        <div style={{ width: "100%" }}>
            <a href={`/projects/${project.id}`} style={{ textDecoration: "none", color: "inherit" }}>
                <img style={{ width: "100%", aspectRatio: 4 / 3, objectFit: "contain" }} src={`${projectData.thumbnail?.data?.attributes?.url}`} alt="Project thumbnail" />
                {/* <p>{projectData.rating}</p> */}
                <h3 style={{ margin: "10px 0 10px 0" }}>{projectData.name}</h3>
                <p style={{ color: "var(--cd-green)", fontSize: 16, margin: "10px 0 0 0" }}>{projectData.clientName}</p>
                <p style={{ fontSize: 14, margin: "1px 0" }}>Typ: {projectData.services.data.map(service => service.attributes?.name).join(", ")}</p>
                <p style={{ fontSize: 14, margin: "1px 0" }}>Rok realizace: {projectData.year}</p>
            </a>
        </div>
    );
}
