import { useState } from "react";
import { TOKEN_KEY } from "../utils";

export default function LoginComponent() {
    const [password, setPassword] = useState("");

    async function login() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                identifier: "admin1",
                password
            })
        });

        const body = await response.json();
        if (!response.ok) {
            alert("Wrong");
            return;
        }

        localStorage.setItem(TOKEN_KEY, body.jwt);
        alert("You've been logged in");
        window.location.reload();
    }

    return (
        <div>
            <input type="password" placeholder="password" value={password} onChange={e => setPassword(e.target.value)} />
            <button onClick={login}>Login</button>
        </div>
    );
}