import MDEditor from '@uiw/react-md-editor';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TOKEN_KEY, isValidToken } from '../utils';
import OutputEditor from '../components/OutputEditor';
import BorderCard from '../components/BorderCard';
import CloseableCard from '../components/CloseableCard';
import Header from '../components/Header';

export default function ProjectIdEditPage() {
  let { id } = useParams();

  const [projectData, setProjectData] = useState(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [name, setName] = useState("");
  const [clientName, setClientName] = useState("");
  const [services, setServices] = useState({});
  const [year, setYear] = useState("");
  const [description, setDescription] = useState("loading");
  const [account, setAccount] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [budget, setBudget] = useState("");
  const [rating, setRating] = useState(3);
  const [internalDescription, setInternalDescription] = useState("loading");

  const [outputs, setOutputs] = useState([]);

  const user_token = localStorage.getItem(TOKEN_KEY);
  if (!isValidToken(user_token)) {
    window.location.href = "/projects";
  }

  useEffect(() => {
    async function getData() {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/services`, {
        headers: {
          'Authorization': `Bearer ${user_token}`
        }
      });
      const json = await res.json();

      const servicesData = services;
      json.data.forEach(item => {
        servicesData[item.id] = {
          id: item.id,
          name: item.attributes.name,
          selected: false
        };
      });
      setServices(servicesData);
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}?populate[thumbnail][populate]=true&populate[services][populate]=true&populate[outputs][populate][0]=file&populate[outputs][populate][1]=images&populate[internal][populate]=true`, {
        headers: {
          'Authorization': `Bearer ${user_token}`
        }
      });
      const json = await res.json();
      const projectData = json.data.attributes;
      setProjectData(projectData);

      setName(projectData.name);
      setClientName(projectData.clientName);
      setYear(projectData.year);
      setDescription(projectData.description);
      setAccount(projectData.internal.data?.attributes.account);
      setProjectManager(projectData.internal.data?.attributes.projectManager);
      setBudget(projectData.internal.data?.attributes.budget);
      setRating(projectData.internal.data?.attributes.rating);
      setInternalDescription(projectData.internal.data?.attributes.internalDescription);

      setOutputs(projectData.outputs.map(output => {
        return {
          type: output.type,
          content: output.content,
          fileId: output.file?.data?.id,
          imageIds: output.images?.data?.map(image => image.id)
        };
      }))

      const newServices = services;
      projectData.services.data.forEach(item => {
        newServices[item.id] = {
          id: item.id,
          name: item.attributes.name,
          selected: true
        };
      });
      setServices(newServices);
    }
    getData();
  }, []);

  async function uploadThumbnail() {
    const form = new FormData();
    console.log(thumbnail);
    form.append('files', thumbnail);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/upload`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${user_token}`
      },
      body: form
    });

    const json = await response.json();

    console.log(response.ok ? "success" : "error");

    return json;
  }

  async function updateData() {

    let newThumbnailJson = null;
    if (thumbnail != null) {
      newThumbnailJson = await uploadThumbnail();
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_token}`
        },
        body: JSON.stringify({
          data: {
            name,
            clientName,
            year,
            description,
            outputs: outputs.map(output => {
              let result = output;
              if (output.fileId)
                result.file = output.fileId;
              if (output.imageIds)
                result.images = output.imageIds;
              return result;
            }),
            thumbnail: newThumbnailJson ? newThumbnailJson[0].id : undefined,
            services: Object.values(services).map(service => {
              if (service.selected)
                return service.id;
              return null;
            }).filter(x => x != null)
          }
        })
      });

      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/api/internal-project-datas/${projectData.internal.data.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_token}`
        },
        body: JSON.stringify({
          data: {
            account,
            projectManager,
            budget,
            rating,
            internalDescription,
          }
        })
      });
      // const json = await response.json();
      alert(response.ok && response2.ok ? "The data has been updated" : "Error");
    } catch (error) {
      alert("An error occured while updating data: ", error);
      console.error(error);
    }
  }

  const serviceClickChange = (id) => {
    setServices(prevServices => ({
      ...prevServices,
      [id]: {
        ...prevServices[id],
        selected: !prevServices[id].selected
      }
    }));
  };

  return (
    <div>
      <Header/>
      {projectData &&
        <>
          <Link to={".."} relative="path" style={{color: "white"}}>Back</Link>
          {projectData.thumbnail.data?.attributes && <img style={{ width: "100%", aspectRatio: 16 / 9, objectFit: "contain" }} src={`${projectData.thumbnail.data.attributes.url}`} alt='Project thumbnail' />}
          <p>Thumbnail: <input type='file' accept="image/*" onChange={e => setThumbnail(e.target.files[0])} /></p>
          <h1>Název: <input type='text' value={name} onChange={e => setName(e.target.value)} /></h1>
          <p>Klient: <input type='text' value={clientName} onChange={e => setClientName(e.target.value)} /></p>
          <p>Služby:<br />
            {Object.values(services).map(service => (
              <React.Fragment key={service.id}>
                <input
                  type='checkbox'
                  id={service.id}
                  checked={service.selected}
                  onChange={() => serviceClickChange(service.id)}
                />
                <label htmlFor={service.id}>{service.name}</label><br />
              </React.Fragment>
            ))}
          </p>
          <p>Rok realizace: <input type='text' value={year} onChange={e => setYear(e.target.value)} /></p>
          {/* <MDEditor
            value={description}
            onChange={setDescription}
          /> */}
          <p>Popis projektu:</p>
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}>
          </textarea>

          <h2>Výstupy</h2>
          {outputs.map((output, index) => {
            return <BorderCard key={index}>
              <CloseableCard onClose={() => {
                console.log(outputs);
                setOutputs(outputs.filter((_, i) => i !== index));
              }}>
                <OutputEditor output={output} onChange={newOutput => {
                  // console.log(index, newOutput);
                  setOutputs(prevOutputs => {
                    const newOutputs = [...prevOutputs];
                    newOutputs[index] = newOutput;
                    return newOutputs;
                  })
                }} />
              </CloseableCard>
            </BorderCard>
          })}
          <button onClick={() => setOutputs(o => {
            const newState = [...o]
            newState.push({ type: "link" });
            return newState;
          })}>Add ➕</button>
          <h2>Interní informace</h2>
          <p>Account: <input type='text' value={account} onChange={e => setAccount(e.target.value)} /></p>
          <p>Projekťák: <input type='text' value={projectManager} onChange={e => setProjectManager(e.target.value)} /></p>
          <p>Rozpočet: <input type='text' value={budget} onChange={e => setBudget(e.target.value)} /></p>
          <p>Hodnocení projektu: <input type='number' min={0} max={5} value={rating} onChange={e => setRating(e.target.value)} /></p>
          <h3>Interní poznámky:</h3>
          {/* <MDEditor
            value={internalDescription}
            onChange={setInternalDescription}
          /> */}
          <p>Interní popis:</p>
          <textarea
            value={internalDescription}
            onChange={e => setInternalDescription(e.target.value)}>
          </textarea><br/>
          <button onClick={updateData}>Save</button>
        </>
      }
    </div >
  );
};
