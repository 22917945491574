import { useEffect, useState } from "react";
import { TOKEN_KEY, isValidToken } from '../utils';

export default function OutputEditor({ output, onChange }) {
  const [type, setType] = useState(output.type);
  const [content, setContent] = useState(output.content ?? "");
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(output.fileId);
  const [newFile, setNewFile] = useState(null);
  const [images, setImages] = useState(null);
  const [imageIds, setImageIds] = useState(output.imageIds);
  const [newImages, setNewImages] = useState(null);

  const [selectedImage, setSelectedImage] = useState(0);

  const [uploadStatus, setUploadStatus] = useState(null);

  const user_token = localStorage.getItem(TOKEN_KEY);
  if (!isValidToken(user_token)) {
    window.location.href = "/projects";
  }

  useEffect(() => {
    setType(output.type);
    setContent(output.content ?? "");
    setFileId(output.fileId);
    setImageIds(output.imageIds);
  }, [output]);

  useEffect(() => {
    let newOutput = {
      type,
    };
    switch (type) {
      case "iframe":
      case "youtube":
      case "vimeo":
      case "link":
        newOutput.content = content;
        break;
      case "pdf":
        newOutput.fileId = fileId;
        break;
      case "gallery":
        newOutput.imageIds = imageIds;
        break;
    }
    onChange(newOutput);
  }, [type, content, fileId, imageIds]);

  useEffect(() => {
    async function uploadFile() {
      if (!newFile) return;

      const form = new FormData();
      // console.log(newFile);
      form.append('files', newFile);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/upload`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${user_token}`
        },
        body: form
      });

      const json = await response.json();
      console.log(response.ok ? "success" : "error");
      setFileId(json[0].id);
      // console.log(json);
      setFile(json[0]);
    }
    uploadFile();
  }, [newFile]);

  useEffect(() => {
    async function uploadFile() {
      if (!newImages) return;

      const form = new FormData();
      console.log(newImages);

      const totalSize = [...newImages].reduce((acc, image) => acc + image.size, 0);
      const totalSizeMB = totalSize / 1024 / 1024;
      console.log(`Total upload size: ${(totalSizeMB).toFixed(2)} MB`);
      if (totalSizeMB >= 100) {
        setUploadStatus(`Too large! (${(totalSizeMB).toFixed(2)} MB)`);
        alert("The maximum upload size is 100MB");
        return;
      }

      [...newImages].forEach(image => form.append('files', image, image.name));

      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${process.env.REACT_APP_API_URL}/api/upload`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${user_token}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          if (percentComplete === 100) {
            setUploadStatus(`99.99%`);
          } else {
            setUploadStatus(`${percentComplete.toFixed(2)}%`);
          }
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const json = JSON.parse(xhr.responseText);
          setImageIds(prevImageIds => [...(prevImageIds ?? []), ...json.map(x => x.id)]);
          setImages(prevImages => [...(prevImages ?? []), ...json]);
          setUploadStatus(`100%`);
        } else {
          setUploadStatus(`Error: ${xhr.status} - ${xhr.statusText}`);
        }
      };

      xhr.onerror = () => {
        setUploadStatus(`Network Error`);
      };

      xhr.send(form);
    }
    uploadFile();
  }, [newImages]);

  useEffect(() => {
    async function getData() {
      if (!fileId) return;
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/upload/files/${fileId}`, {
        headers: {
          'Authorization': `Bearer ${user_token}`
        }
      });
      const json = await res.json();
      setFile(json)
    }
    getData();
  }, [fileId]);

  useEffect(() => {
    async function getData() {
      if (!imageIds) return;

      const images = await Promise.all(imageIds.map(async imageId => {
        if (!imageId) return;
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/upload/files/${imageId}`, {
          headers: {
            'Authorization': `Bearer ${user_token}`
          }
        });
        const json = await res.json();
        return json;
      }));
      setImages(images);
    }
    getData();
  }, [imageIds]);

  const removeImage = (index) => {
    console.log("removing", index);
    setImages(prevImages => prevImages.filter((item, i) => i !== index))
    setImageIds(prevImages => prevImages.filter((item, i) => i !== index))
  }

  const renderOutput = () => {
    switch (type) {
      case "iframe":
        return <input type="text" placeholder="<iframe ... />" value={content} onChange={e => setContent(e.target.value)} />
      case "youtube":
        return <input type="text" placeholder="youtube embed link" value={content} onChange={e => setContent(e.target.value)} />;
      case "vimeo":
        return <input type="text" placeholder="vimeo embed link" value={content} onChange={e => setContent(e.target.value)} />;
      case "link":
        return <input type="text" placeholder="https:// ... " value={content} onChange={e => setContent(e.target.value)} />;
      case "pdf":
        return (
          <>
            {file && <>
              <a href={`${file.url}`} target="blank">{file.name}</a>
            </>}
            <input type='file' accept="application/pdf" onChange={e => setNewFile(e.target.files[0])} />
          </>);
      case "gallery":
        return <>
          <select onChange={e => setSelectedImage(e.target.selectedIndex)}>
            {images && images.map((image, index) => <option key={`${index}_${image.name}`}>{image.name}</option>)}
          </select>
          <button onClick={() => removeImage(selectedImage)}>Remove Selected Image</button>
          &emsp;&emsp;Add files:&nbsp;
          <input type='file' multiple accept="image/*" onChange={e => setNewImages(e.target.files)} />
          <span>{uploadStatus && `${uploadStatus}`}</span>
        </>;
      default:
        return null;
    }
  };

  return (
    <>
      <label>Type:
        <select value={type} onChange={e => setType(e.target.value)}>
          <option value="vimeo">vimeo</option>
          <option value="youtube">youtube</option>
          <option value="iframe">iframe</option>
          <option value="gallery">gallery</option>
          <option value="pdf">pdf</option>
          <option value="link">link</option>
        </select>
      </label>
      {renderOutput()}
      <br />
    </>
  );
}
