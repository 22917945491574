import React from 'react';
import './ResponsiveTable.css';

export default function ResponsiveTable({ children }) {
    return (
        <div className="responsive-table">
            {React.Children.map(children, child => (
                <div className="cell">
                    {React.cloneElement(child, { style: { ...child.props.style, opacity: 0.5 } })}
                </div>
            ))}
            {/* {children} */}
        </div>
    );
}