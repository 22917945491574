import { jwtDecode } from 'jwt-decode';

export const TOKEN_KEY = "TOKEN";

export const isValidToken = (token) => {
  if (!token)
    return false;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp > currentTime;
  } catch (error) {
    // Handle error (e.g., invalid token)
    console.error('Invalid token:', error);
    return false; // Consider invalid tokens as expired
  }
};
